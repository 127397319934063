import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { COLLECTIONS } from '../../constants'

export default function ImagewithText() {
    const mobileImageUrl = 'https://cdn.shopify.com/s/files/1/0131/2368/2363/files/nose_piercing_jewellery_mobile_hero.png?v=1656153074'
    const desktopImageUrl = 'https://cdn.shopify.com/s/files/1/0131/2368/2363/files/nose_piercing_jewellery_desktop_hero.png?v=1656135616'
    const imageSection = COLLECTIONS.HOMEPAGE_SECONDARY_SECTION.imageSection

    return (
        <React.Fragment>
            <div className="relative sm:hidden">
                <div className="w-full bg-blue-secondary mobile-hero">
                    <StaticImage
                        alt={imageSection.imageAlt}
                        src={mobileImageUrl}
                        layout='constrained'
                        className='w-full h-full'
                        placeholder='blurred'
                        objectFit='contain'
                        // objectPosition={'0 0'}
                    />
                </div>
                <div className={`absolute top-0 left-0 w-full h-full flex items-end ${imageSection.textPosition === 'left' && 'justify-start'}${imageSection.textPosition === 'right' && 'justify-end'}`}>
                    <div className="w-full h-full flex justify-center py-12">
                        <div className={`flex items-end h-full mx-8 ${imageSection.textPosition === 'right' ? 'justify-end' : 'justify-start'}`} style={{width: '21em'}}>
                            <div className={`text-white ${imageSection.textPosition === 'right' && 'text-right'}`}>
                                <h3 className="text-sxl uppercase font-normal">{imageSection.text}</h3>
                                <Link to={imageSection.bookLink}>
                                    <h1 className="text-2xl uppercase font-semibold underline">{imageSection.ctaText}</h1>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative hidden sm:block">
                <div className="w-full bg-blue-secondary desktop-hero">
                    <StaticImage  
                        alt={imageSection.imageAlt}
                        src={desktopImageUrl}
                        loading="lazy"
                        width={1200}
                        className='w-full h-full'
                        placeholder='blurred'
                        layout='constrained'
                        objectFit='contain'
                    />
                </div>
                <div className={`absolute top-0 left-0 w-full h-full flex items-end px-17 sm:py-4 md:py-6 lg:py-8 ${imageSection.textPosition === 'left' && 'justify-start'}${imageSection.textPosition === 'right' && 'justify-end'}`}>
                    <div className="w-full h-full flex items-center justify-center pl-4">
                        <div className={`h-full relative text-white flex items-center ${imageSection.textPosition === 'right' ? 'justify-end' : 'justify-start'}`} style={{width: '64em'}}>
                            <div className={`absolute top-0 lg:top-6 xl:top-8 right-0 text-white tracking-widest ${imageSection.textPosition === 'right' && 'text-right'}`}>
                                <h3 className="sm:text-base lg:text-xl font-normal uppercase">{imageSection.text}</h3>
                                <h1 className="sm:text-xl text-2xl md:text-3xl lg:text-4xl">{imageSection.heading}</h1>
                            </div>
                            <Link to={imageSection.bookLink} className="block text-center no-underline border-2 border-white p-4 mt-16 mr-8">
                                <h1 className="sm:text-xl md:text-2xl uppercase font-semibold tracking-widest">{imageSection.ctaText}</h1>
                            </Link>
                            <div className="absolute bottom-0 right-0">
                                <Link className='underline uppercase sm:text-base md:text-xl ml-16' to={imageSection.learnLink}>{imageSection.linkText}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
