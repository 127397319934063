import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getShopifyImageData } from "../../utils/helpers"

export default function ImageCard({
    image,
    text,
    link,
    linkText
}) {
    const shopifyImageData = getShopifyImageData({
        image: image,
        width: 640,
        layout: 'constrained',
        format: 'png'
    })
    return (
        <div className="relative">
            <div className="flex items-center justify-center image-card-wrapper">
                <GatsbyImage
                    image={shopifyImageData}
                    alt={image.altText}
                    placeholder="blurred"
                    className='w-full'
                />
            </div>
            <div className={`absolute top-0 left-0 w-full h-full flex items-end`}>
                <div className="w-full h-full flex justify-center py-8">
                    <div className="flex items-end w-full sm:w-image-card h-full px-8">
                        <div className={`text-white`}>
                            <h3 className="text-base uppercase font-normal">{text}</h3>
                            <Link to={link}>
                                <h1 className="text-xl uppercase font-semibold">{linkText}</h1>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}