import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { COLLECTIONS } from '../../constants'

export default function ImagewithText() {
    const mobileImageUrl = 'https://cdn.shopify.com/s/files/1/0131/2368/2363/files/Childrens_Ear_Piercing.png?v=1655319713'
    const desktopImageUrl = 'https://cdn.shopify.com/s/files/1/0131/2368/2363/files/Desktop_childrens_ear_piercing.png?v=1655314940'
    const imageSection = COLLECTIONS.HOMEPAGE_HERO_COLLECTION.imageSection

    return (
        <React.Fragment>
            <div className="relative sm:hidden">
                <div className="w-full bg-red-secondary mobile-hero">
                    <StaticImage
                        alt={imageSection.imageAlt}
                        src={mobileImageUrl}
                        loading="lazy"
                        layout='constrained'
                        className='w-full h-full'
                        placeholder='blurred'
                        objectFit='contain'
                    />
                </div>
                <div className={`absolute top-0 left-0 w-full h-full flex items-end ${imageSection.textPosition === 'left' && 'justify-start'}${imageSection.textPosition === 'right' && 'justify-end'}`}>
                    <div className="w-full h-full flex justify-center py-12">
                        <div className="flex items-end h-full mx-8" style={{width: '21em'}}>
                            <div className={`text-white ${imageSection.textPosition === 'right' && 'text-right'}`}>
                                <h1 className="text-2xl uppercase font-normal mb-4">{imageSection.text}</h1>
                                <Link to={imageSection.buttonLink_1} className="inline-block py-2 px-4 bg-white mb-2">
                                    <h4 className="text-base m-0 text-black uppercase font-bold text-center">{imageSection.buttonText_1}</h4>
                                </Link>
                                <Link to={imageSection.buttonLink_2} className="inline-block py-2 px-4 bg-white">
                                    <h4 className="text-base m-0 text-black uppercase font-bold text-center">{imageSection.buttonText_2}</h4>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative hidden sm:block">
                <div className="w-full bg-red-secondary desktop-hero">
                    <StaticImage  
                        alt={imageSection.imageAlt}
                        src={desktopImageUrl}
                        width={1200}
                        loading="lazy"
                        className='w-full h-full'
                        placeholder='blurred'
                        layout='constrained'
                        objectFit='contain'
                    />
                </div>
                <div className={`absolute top-0 left-0 w-full h-full flex items-end px-17 sm:py-4 md:py-6 lg:py-8 ${imageSection.textPosition === 'left' && 'justify-start'}${imageSection.textPosition === 'right' && 'justify-end'}`}>
                    <div className="w-full h-full flex items-center justify-center pl-4">
                        <div className={`h-full relative text-white flex flex-col items-start justify-center px-8 mt-16 ${imageSection.textPosition === 'right' && 'text-right'}`} style={{width: '64em'}}>
                            {/* <div className="absolute top-0 lg:top-6 xl:top-8 left-0 text-white tracking-widest">
                                <h3 className="sm:text-base lg:text-xl font-normal uppercase">{imageSection.text}</h3>
                                <h1 className="sm:text-xl text-2xl md:text-3xl lg:text-4xl">{imageSection.heading}</h1>
                            </div> */}
                            <h1 className="text-2xl uppercase font-normal mb-4">{imageSection.text}</h1>
                            <div className="flex items-center justify-start">
                                <Link to={imageSection.buttonLink_1} className="inline-block py-2 px-4 bg-white mr-4">
                                    <h4 className="text-base m-0 text-black uppercase font-bold text-center">{imageSection.buttonText_1}</h4>
                                </Link>
                                <Link to={imageSection.buttonLink_2} className="inline-block py-2 px-4 bg-white">
                                    <h4 className="text-base m-0 text-black uppercase font-bold text-center">{imageSection.buttonText_2}</h4>
                                </Link>
                            </div>
                            {/* <div className="absolute bottom-0 left-0">
                                <Link className='underline uppercase sm:text-base md:text-xl ml-16' to={imageSection.learnLink}>{imageSection.linkText}</Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
