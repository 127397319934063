import React from 'react'

export default function RichText({ heading, bodyText, customClass }) {
    const [isTruncated, setIsTruncated] = React.useState(true)
    const [isTruncable, setIsTruncable] = React.useState(true)
    const truncatedText = bodyText.slice(0, 200) + '...';

    React.useEffect(() => {
        const width = window.innerWidth

        if (width > 920) {
            setIsTruncable(false)
            setIsTruncated(false)
        }
    }, [setIsTruncable, setIsTruncated])

    return (
        bodyText !== '' && (
            <div className={`w-full p-8 ${customClass}`}>
                <div className="section-wrapper">
                    <div className="section-header">
                        <h2 className="text-xl mb-4 text-center">{heading}</h2>
                    </div>
                    <div className="section-body rich-text">
                        <div className={`text-sm`} dangerouslySetInnerHTML={{ __html: isTruncated ? truncatedText : bodyText }}></div>
                        {
                            isTruncable && (
                            <div className="py-3">
                                {
                                    isTruncated ? (
                                        <button onClick={() => setIsTruncated(false)}>Read more</button>
                                    ) : (
                                        <button onClick={() => setIsTruncated(true)}>Read less</button>
                                    )
                                }
                            </div>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    )
}