import * as React from "react"
import HeroSection from "../components/sections/hero"
import ImagewithText from "../components/sections/image-with-text"
import RichText from "../components/sections/rich-text"
import { Layout } from "../components/layout/layout"
import ImageCard from "../components/sections/image-card"
import { COLLECTIONS } from "../constants"
import { STRINGS } from "../constants"

export default function IndexPage() {
  return (
    <Layout>
      <HeroSection />
      <ImagewithText />
      <div className="block">
        <div className="py-4 mx-auto" style={{maxWidth: 300 * 3 + 24}}>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 px-4">
            {
              COLLECTIONS.HOME_COLLECTIONS.map((item, index) => (
                <ImageCard
                  key={index}
                  image={item.image}
                  text={item.text}
                  link={item.link}
                  linkText={item.linkText}
                />
              ))
            }
          </div>
        </div>
      </div>
      <RichText
        heading={STRINGS.HOMEPAGE_RICHTEXT_HEADING}
        bodyText={STRINGS.HOMEPAGE_RICHTEXT_BODY}
        customClass={'mx-auto px-8 sm:px-8 max-w-7xl'}
      />
    </Layout>
  )
}
